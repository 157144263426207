/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

body {
  background: #e5e5e5 !important;
}

.mainContainer {
  margin-top: 25px;
  margin-bottom: 25px;
}

.profilePicDisplay {
  border-radius: 1000px;
}

.profilePicSM {
  width: 60px;
}

.profilePicLG {
  width: 120px;
}

.formTitle {
  text-align: right;
  color: #535353;
}

.formDataReadOnly {
  text-align: left;
  color: black;
}

.formDataHighLight {
  background-color: #f4f4f4;
}

.formContainer {
  /* max-width: 800px !important; */
}

.formGroup {
  padding: 10px;
}

.formGroup>* {
  padding: 10px;
}

.profileDetailsForm {
  margin-top: 20px;
}

.startSellingButton {
  border: 1px solid #161f92 !important;
  color: #161f92 !important;
}

.startSellingButton img {
  height: 19px;
  margin-right: 10px;
}

.startSellingButton:hover {
  background-color: #161f92 !important;
  color: white !important;
}

.startSellingButton:hover>img {
  filter: brightness(1000%);
}

.profileNav .nav-link {
  color: black !important;
  text-align: left;
  border-radius: 5px;
}

.profileNav .nav-link:hover {
  background-color: #161f92;
  color: white !important;
}

.profileNav .nav-link.active {
  background-color: #161f92;
  color: white !important;
}

.profileNav .nav-link.active>img {
  filter: brightness(1000%);
}

.profileNav .nav-link:hover>img {
  filter: brightness(1000%);
}

.profileNav img {
  height: 24px;
  margin-right: 10px;
}

.profileDetailsForm .formTitle {
  margin-right: 40px;
}

.productsTab {
  background: white;
  margin-bottom: 15px;
}

/* use to set default icon size */
.productsTab img {
  height: 18px;
  margin-right: 10px;
}

.productsTab .nav-link {
  color: black !important;
  border: 0px !important;
  padding: 15px 0px;
}

.productsTab .nav-link:hover {
  border: 0px;
}

.productsTab .nav-link {
  border-bottom: 5px solid #ffd700 !important;
}

.productsTab .nav-link:hover {
  border-bottom: 5px solid #161f92 !important;
}

.productsTab .nav-link.active {
  border-bottom: 5px solid #161f92 !important;
}

.productPreviewContainer {
  margin-bottom: 15px;
}

.productPreview {
  margin: 15px 0px;
  background: white;
  padding: 20px 30px;
}

.salesProductPreview {
  margin: 0px 0px 0px 0px;
  background: white;
  padding: 20px 0px !important;
}

.salesShopName {
  margin: 0px 0px 5px 0px;
  background: white;
  padding: 20px 30px;
}

.productPreviewBtn button {
  width: 200px;
  margin: 0px 7px;
}

.salesProduct {
  margin-bottom: 20px;
  padding: 0px 30px;
}

.salesConfirmMessage {
  margin-bottom: 20px;
  padding: 0px 12px;
}

.transactionContainer {
  background: rgba(49, 103, 233, 0.1);
  padding: 5px 30px;
}

.transactionShipContainer {
  text-align: left;
  padding: 5px 30px;
}

.transactionCompletedContainer {
  text-align: left;
  padding: 5px 30px;
  color: #2eb37c;
}

.transactionContainer h6 {
  margin: 0;
}

.productListContainer {
  cursor: pointer;
}

.modalConfirmPickup .modal-content {
  background: rgba(59, 59, 83, 0.7);
  backdrop-filter: blur(2px);
  color: white;
  border-radius: 15px;
  /* min-width: 750px; */
}

.btnConfirmPickup {
  width: 400px;
  height: 50px;
  background: #3167e9;
  border-radius: 15px !important;
}

.bgTransaction1 {
  text-align: left;
  background: rgba(49, 103, 233, 0.1);
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  padding: 20px;
  margin-bottom: 15px;
}

.bgTransaction2 {
  text-align: left;
  background: white;
  padding: 20px;
  margin-bottom: 15px;
}

.addVariantBtn:hover>img {
  filter: brightness(1000%);
}

.default-btn {
  background-color: #FFD700;
  border-color: #FFD700;
  border-radius: 28px !important;
}

.btn-space {
  margin-right: 5px !important;
}

.removeLineLink {
  color: white !important
}

.height-full {
  max-height: 18rem !important;
  width: auto;
}

.color-white {
  color: white !important;
}

.color-black {
  color: black !important;
}

/* Shop Ratings */

.shop-rating-title {
  margin: 0 30px;
  font-weight: bold;
}

canvas {
  width: 150px !important;
  height: 150px !important;
}

.doughnut {
  position: absolute;
}

.donut-inner h5 {
  position: relative;
  margin: 66px 66px;
}

.star-rating {
  display: flex;
  align-items: flex-start;
}

.star-rating span {
  position: relative;
  top: 2px;
  color: #808080;
}

.rating-text {
  color: "#000";
  padding-left: 20px;
  font-size: 17px;
}

.shop-ratings-btn button {
  font-size: 15px;
}

.product-ratings {
  margin-top: 40px;
}

.product-rating-title {
  margin: 0 30px;
}

.review {
  margin: 10px 40px 0;
  background-color: white !important;
}

.review h6 {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.review p {
  font-size: 14px;
}

.buyer-img {
  border-radius: 100px;
  width: 40px;
  height: 40px;
}

.f-gray {
  color: #808080;
}

.seller-response {
  background: #F4F4F4;
  padding: 10px;
  margin: 0 43px 20px 57px;
}

.response-box {
  margin: 0 43px 20px 57px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background: #0275d8 !important;
}

.space-cat {
  padding-left: 10px;
  padding-right: 10px;
}

.read-or-hide {
  color: blue;
  cursor: pointer;
}

.buyAgain {
  margin: 0 auto;
  padding: 10px;
}

.rightNavMenuClass .dropdown-toggle::after {
  display: none;
}

.image-upload > input {
    display: none;
}

.slick-slide img {
    display: unset !important;
}

.pointer {
  cursor: pointer;
}