.buyer-navbar-custom {
  background-color: #161F92 !important;
  border-color:#161F92 !important;
  width:'100%'
}

.buyer-navbar-custom .navbar-brand {
  color:#FFFFFF !important
}

button.customMenuToggler {
  background-color:white !important;
  border-color:white !important;
}

.buyer-navbar-custom .nav-link {
  color:#FFFFFF !important
}

.sub_header_custom_nav .nav-link {
  color:#000000 !important
}

.sub_header_custom_nav {
  background-color:white !important
}


.sliderDataCategory label {
  margin-top:10px;
  float:left
}


.sliderDataCategory h6 {
  padding:10px;
  margin:5px;
  color:#FFFFFF;
  background-color: #3167E9;
}


h6.active_cat {
  padding:10px;
  margin:5px;
  color:#FFFFFF;
  background-color: #FFC350 !important;
}

.sliderZoomData img:hover {
  border:2px solid #161F92;
}



.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  border-radius: 0 !important;
  background-color: #161F92 !important ;
  border-color: #161F92 !important;
}

.page-link {
  z-index: 3;
  color: #000000 !important;
  border-radius: 0 !important;
  background-color: #F4F4F4 !important ;
  border: none  !important;
}

li {
  list-style-type: none !important;
}
.validation {
  float: left;
  color: #CF0E0E;
}

p.validation {
  padding:10px;
  font-size: 13px;
}

.customInput.form-control {
  background-color:#f4f4f4 !important;
  border: none !important
}

.img-wrapper {
  overflow: hidden;
}

img.hover-zoom {
  transition: all 0.3s ease 0s;
  align-items: center;
  max-height: 40rem;
  max-width: inherit;
  width: auto;
}

img.hover-zoom:hover {
  transform: scale(1.25);
}

Button.colorBtnVariant {
  color:#535353 !important;
  background-color:#F4F4F4;
  border-color: #F4F4F4;
  font-weight: 400;
  font-size:13px;
}

Button.colorBtnVariant:hover {
  color:#535353 !important;
  opacity:0.6;
  background-color:#F4F4F4;
  border-color: #F4F4F4;
  font-weight: 400;
  font-size:13px;
}

Button.sizeBtnVariant {
  color:#535353 !important;
  background-color:#F4F4F4;
  border-color: #F4F4F4;
  font-weight: 400;
  font-size:13px;
}

Button.sizeBtnVariant:hover {
  color:#535353 !important;
  opacity:0.6;
  background-color:#F4F4F4;
  border-color: #F4F4F4;
  font-weight: 400;
  font-size:13px;
}

Button.quantityBtnVariant {
  color:#535353 !important;
  background-color:#F4F4F4;
  border-color: #F4F4F4;
  font-weight: 400;
  font-size:17px;
  border:0.20px solid #535353;
  border-radius: 0%;
}

Button.quantityBtnVariantCount:hover {
  color:#535353 !important;
  background-color:#F4F4F4;
  border-color: #F4F4F4;
  font-weight: 400;
  font-size:17px;
  border:0.20px solid #535353;
  border-radius: 0%;
}

Button.quantityBtnVariantCount {
  color:#535353 !important;
  background-color:#F4F4F4;
  border-color: #F4F4F4;
  font-weight: 400;
  font-size:17px;
  border:0.20px solid #535353;
  border-radius: 0%;
}

Button.quantityBtnVariant:hover {
  color:#FFFFFF !important;
  background-color:#535353;
  border-color: #535353;
  font-weight: 400;
  font-size:17px;
  border:0.20px solid #535353;
  border-radius: 0%;
}

Button {
  border-radius: 0% !important;
}

.card {
  border-radius: 0% !important;
}

Button.AddToCartBtn {
  font-size:20px;
  background-color: #FFC350;
  border-color:#FFC350;
  border-radius: 15px !important;
}
Button.AddToCartBtn:hover {
  font-size:20px;
  background-color: #FFC350;
  border-color:#FFC350;
  opacity: 0.6;
}

Button.BuyNowBtn {
  font-size:20px;
  background-color: #3167E9;
  border-color:#3167E9;
  border-radius: 15px !important;
}
Button.BuyNowBtn:hover {
  font-size:20px;
  background-color: #3167E9;
  border-color:#3167E9;
  opacity: 0.6;
}

/* .cartTable tbody, td, tfoot, th, thead, tr {
  border-style:none !important;
} */

.cartTable .form-check-input {
  border: 2px solid #2C3E50;
}

.cartTable .form-check-input:checked:before{
  background-color:green !important;
}

.modalCartRemoveItem .modal-content {
  border-radius: 15px !important;
}

.modalCartRemoveItem .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content:center !important;
  padding: .75rem;
  border-top: 0px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
}

.modalShippingOptions .modal-content {
  border-radius: 15px !important;
}

.modalShippingOptions .modal-body {
  background: rgba(49, 103, 233, 0.1) !important;
}

.modalShippingOptions .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content:center !important;
  padding: .75rem;
  border-top: 0px solid #dee2e6;
  background: rgba(49, 103, 233, 0.1) !important;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
}

.modalPaymentOptions .modal-content {
  border-radius: 15px !important;
  
}

.modalPaymentOptions .modal-body {
  background: rgba(49, 103, 233, 0.1) !important;
}


.modalPaymentOptions .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content:center !important;
  padding: .75rem;
  border-top: 0px solid #dee2e6;
  background: rgba(49, 103, 233, 0.1) !important;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
}

Button.yesBtnRemoveItem {
  background-color:#3167E9 !important;
  border-color:#3167E9 !important;
  border-radius:15px !important;
}

Button.noBtnRemoveItem {
  background:none;
  color:#3167E9 !important;
  border-color:#3167E9 !important;
  border-radius:15px !important;
}

Button.noBtnRemoveItem:hover {
  background:none;
  color:#3167E9 !important;
  border-color:#3167E9 !important;
  border-radius:15px !important;
}

Button.placeOrderBtn {
  background-color:#3167E9 !important;
  border-radius: 15px !important;
  width:170px;
  border-color:#3167E9 !important;
}

.formNewAddress input {
  
  border-style:none !important;
  background-color: #F4F4F4;
}

.formNewAddress input {
  font-size:15px;
}

.formNewAddress select {
  font-size:15px;
  border-style:none !important;
  background-color: #F4F4F4;
  color:'#747474'
}
.formNewAddress .form-select {
  color:#747474 !important
}

.formNewAddress textarea {
  font-size:15px;
  border-style:none !important;
  background-color: #F4F4F4;
  color:'#747474'
}

.formNewAddress .form-floating>label {
  color:#747474 !important;
  font-size:13px !important;
}

.form-check-input:checked {
  background-color: #FFD700 !important;
  color:#0000 !important;
}

.custom-checkbox .form-check-label {
  font-size:13px !important;
  font-weight: 300;
}


Button.btnSaveNewAddress {
  background-color:#3167E9;
  border-color: #3167E9;
  font-weight: 400;
  padding:13px;
  color:#FFFFFF;
  border-radius: 15px !important;
  font-size:17px !important;
}

/* .box.arrow-top {
  margin-top: 40px;
} */

.box.arrow-top:after {
  content: " ";
  position: absolute;
  right: 30px;
  top: -7px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid white;
}

.notification-drop.dropdown-menu-end {
  width:460px !important;
}

.sliderEventDataCategory .card-body {
  height:100.15px !important
}

.mainContainer {
  margin: 10px 0px;
}

.profilePicDisplay {
  border-radius: 1000px;
}

.profilePicSM {
  width: 60px;
}

.profilePicLG {
  width: 120px;
}

.formTitle {
  text-align: right;
  color: #535353;
}

.formDataReadOnly {
  text-align: left;
  color: black;
}

.formDataHighLight {
  background-color: #f4f4f4;
}

.formContainer {
  /* max-width: 800px !important; */
}

.formGroup {
  padding: 10px;
  font-size: 14px;
  font-weight: 300;
  align-items: center;
}

.formGroup > * {
  padding: 10px 0px;
}

.profileDetailsForm {
  margin-top: 20px;
}

.startSellingButton {
  border: 1px solid #161f92 !important;
  color: #161f92 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
}

.startSellingButton img {
  height: 19px;
  margin-right: 10px;
}

.startSellingButton:hover {
  background-color: #161f92 !important;
  color: white !important;
}

.startSellingButton:hover > img {
  filter: brightness(1000%);
}

.profileNav .nav-link {
  color: black !important;
  text-align: left;
  border-radius: 5px;
}

.profileNav .nav-link:hover {
  background-color: #161f92;
  color: white !important;
}

.profileNav .nav-link.active {
  background-color: #161f92;
  color: white !important;
}

.profileNav .nav-link.active > img {
  filter: brightness(1000%);
}

.profileNav .nav-link:hover > img {
  filter: brightness(1000%);
}

.profileNav img {
  height: 24px;
  margin-right: 10px;
}

.productsTab {
  background: white;
}

/* use to set default icon size */
.productsTab img {
  height: 18px;
  margin-right: 10px;
}

.productsTab .nav-link {
  color: black !important;
  border: 0px !important;
  padding: 15px 0px;
}

.productsTab .nav-link:hover {
  border: 0px;
}

.productsTab .nav-link {
  border-bottom: 5px solid #ffd700 !important;
}

.productsTab .nav-link:hover {
  border-bottom: 5px solid #161f92 !important;
}

.productsTab .nav-link.active {
  border-bottom: 5px solid #161f92 !important;
}

.productPreview {
  margin: 15px 0px;
  background: white;
  padding: 20px 0px;
}

.salesProductPreview {
  margin: 0px 15px 0px;
  background: white;
  padding: 20px 0px !important;
}

.salesShopName {
  margin: 15px 0px 5px 0px;
  background: white;
  padding: 20px 0px;
}

.productPreviewBtn button {
  width: 200px;
  margin: 0px 7px;
}

.salesProduct {
  margin-bottom: 20px;
  padding: 0px 30px;
}

.salesConfirmMessage {
  margin-bottom: 20px;
  padding: 0px 12px;
}

.transactionContainer {
  background: rgba(49, 103, 233, 0.1);
  padding: 5px 30px;
}

.transactionShipContainer {
  text-align: left;
  padding: 5px 30px;
}

.transactionCompletedContainer {
  text-align: left;
  padding: 5px 30px;
  color: #2eb37c;
}

.transactionContainer h6 {
  margin: 0;
}

.productListContainer {
  cursor: pointer;
}


.formDataReadOnly input {
  
  border-style:none !important;
  background-color: #F4F4F4;
  border-radius: 0px;
}

.formDataReadOnly input {
  font-size:15px;
}

.formDataReadOnly select {
  font-size:15px;
  border-radius: 0px;
  border-style:none !important;
  background-color: #F4F4F4;
  color:'#747474'
}
.formDataReadOnly .form-select {
  color:#747474 !important
}

.formDataReadOnly textarea {
  font-size:15px;
  border-style:none !important;
  background-color: #F4F4F4;
  color:'#747474'
}

Button.saveChangePassword {
  width:300px;
  background-color:#3167E9 !important;
  border-radius: 15px !important;
  border-color:#3167E9 !important
}

Button.viewOrdersBtn {
  background:none;
  color:#3167E9 !important;
  border-color:#3167E9 !important;
  border-radius:5px !important;
  font-size: 12px;
}

Button.viewOrdersBtn:hover {
  background:none;
  color:#3167E9 !important;
  border-color:#3167E9 !important;
  border-radius:5px !important;
  font-size: 12px;
}

.tabTransactionList .nav-tabs .nav-link {
  background-color:#FFFFFF !important;
  border:none;
  border-bottom: 2px solid #FFD700;
  
}

.tabTransactionList .nav-tabs .nav-link.active {
  background-color:#FFFFFF !important;
  border:none;
  border-bottom: 2px solid #161F92;
  
}

.tabTransactionList .nav-tabs {
  border-bottom: none !important;
  
}

.TransactionTabs .nav-link {
  padding: 10px 50px 10px 40px;
}

.SuccessRequestModal .modal-content {
  background: rgba(59, 59, 83, 0.7) !important;
  backdrop-filter: blur(6px);
  
}

.cancelRequestModal .modal-content {
  border-radius: 0px !important;
}

.cancelRequestModal .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content:center !important;
  padding: .75rem;
  border-top: 0px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
}

Button.cancelRequestBtn {
  background:none;
  color:#3167E9 !important;
  border-color:#3167E9 !important;
  border-radius:15px !important;
  font-size:19px;
}

Button.cancelRequestBtn:hover {
  background:none;
  color:#3167E9 !important;
  border-color:#3167E9 !important;
  border-radius:15px !important;
  font-size:19px;
}

Button.confirmCancelRequestBtn {
  background:3167E9;
  color:#FFFFFF !important;
  border-color:#3167E9 !important;
  border-radius:15px !important;
  font-size:19px;
}

Button.confirmCancelRequestBtn:hover {
  background:#3167E9;
  color:#FFFFFF !important;
  border-color:#3167E9 !important;
  border-radius:15px !important;
  font-size:19px;
}

.cancelRequestModal input {
  
  border-style:none !important;
  background-color: #F4F4F4;
  border-radius: 0px;
}

.cancelRequestModal textarea {
  
  border-style:none !important;
  background-color: #F4F4F4;
  border-radius: 0px;
}

Button.EventsViewMoreBtn {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #161F92;
  border-color:'#161F92';
  color: white;
  font-size: 18px;
  padding: 12px 24px;
  border: none;
  width:300px;
  cursor: pointer;
  border-radius: 15px !important;
}

Button.EventsViewMoreBtn a {
  text-decoration: none;
  color:#FFFFFF
}

.img-carousel {
  max-height: 20rem;
  width: auto;
}

.carousel-indicators button {
  background-color: white !important;
  border-radius: 50% !important;
  height: 10px !important;
  width: 10px !important;
}

.carousel-indicators button.active {
  background-color: #3167E9 !important;
}

.label_variety_btn {
  height: 7em;
  width: 9em;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px dashed #3167E9;
  cursor: pointer;
}


.btn.btn-primary.upload_seller_doc_btn {
  border-color: #FFD700;
  background-color:#FFD700;
  border-radius: 28px !important;
  font-size: 15px;
}

span.btn.btn-primary.btn-file {
  background-color: #00A2FF !important;
  border-color: #00A2FF !important;
  border-radius: 5%;
  line-height: 0.5 !important;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}


.no-data{
  background: white;
  height:400px;
  color:#D1D1D1;
}

.error {
  border: 1px solid #FF6565 !important;
}

.error-dashed {
  border: 1px dashed #FF6565 !important;
}

.text-warning{
  margin-left: 17%;
  padding-top: .5%;
  font-size: 12px;
  color:red !important;
}

.space-top-bottom{
  padding-top: 5px;
  padding-bottom: 5px;
}
.idol-mall-product-name{
  background: rgb(2,0,36);
  background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(22,31,146,1) 2%, rgba(22,31,146,1) 100%, rgba(0,0,0,1) 100%);
  box-shadow: 7px 4px 5px 0px rgba(0,0,0,0.53);
  -webkit-box-shadow: 7px 4px 5px 0px rgba(0,0,0,0.53);
  -moz-box-shadow: 7px 4px 5px 0px rgba(0,0,0,0.53);
  border-radius: 0 10px 1px 0;
  left:10;
  top:20;
  width:50;
  height:20;
  z-index:1;
}

.footerContent {
  font-size: 10px;
}